*{
    color: #9C93A3;
}

.MuiFormLabel-root {
    color: #ACACAC !important;
}

.MuiInputBase-root {
    color: #ACACAC !important;
}

#outlined-basic {
    color: #ACACAC !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #ACACAC !important;
}



.css-1g12qau-MuiSvgIcon-root-MuiNativeSelect-icon {
    color: #ACACAC !important;
}

.svgicon {
    margin-bottom: -4% !important;
}


.tabs {
    display: flex;
   
}

/* not active button changes */
.tab {
    width: 100%;
    border: none;
    
}

/* .tab:not(:last-child) {
    
} */

/* onactive button changes */
/* .tab.active {
    
    font-size: 14px
}

.active {
    font-size: 14 !important;
    padding-bottom: 1;
    position: relative;
    color: #b46228 !important;
    width: 60%;
    font-weight: 500;
    Line-height: 18px
}

.active::after {
    background-color: none;
    bottom: 0;
    left: 0;
} */

.act {
    padding: 12px 16px 12px 20px;
    color: #00FFFF !important;
    width: 100%;
    background-color: #242833;
    transition: all 0.3s ease;
}

.panel {
    display: none;
    background-color: #262626;
    padding: 10px;
}

.panel.act{
    display: inline;
    width: 100%;
    height: 100%
}

