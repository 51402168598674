.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .stack-container {
    width: 300px;
    overflow: hidden;
    transition: width 0.3s ease;
  }
  
  .stack {
    width: 100%;
    height: 200px;
    background-color: #2196f3;
  }
  
  .expanded {
    width: 0;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}