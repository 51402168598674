:root {
    --white: #fff;
    --darkblue: #1c1f28;
    --lightblue: #1c1f28;
  }
  
  * {
    padding: 0;
    margin: 0;
  }
  
  
  .container {
    max-width: 1000px;
    padding: 0 15px;
    margin: 0 auto;
  }
  
  h1 {
    font-size: 1.5em;
  }
  
  /* TABLE STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .table-wrapper {
    overflow-x: auto;
  }
  
  .table-wrapper::-webkit-scrollbar {
    height: 8px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: var(--darkblue);
    border-radius: 40px;
  }
  
  .table-wrapper::::-webkit-scrollbar-track {
    background: var(--white);
    border-radius: 40px;
  }
  
  .table-wrapper table {
    margin: 50px 0 20px;
    border-collapse: collapse;
    text-align: center;
  }
  
  .table-wrapper table th,
  .table-wrapper table td {
    padding: 10px;
    min-width: 75px;
  }
  
  .table-wrapper table th {
    color: var(--white);
    background: var(--darkblue);
  }
  
  .table-wrapper table tbody tr:nth-of-type(even) > * {
    background: var(--lightblue);
  }
  
  .table-wrapper table td:first-child {
    display: grid;
    /* grid-template-columns: 25px 1fr; */
    grid-gap: 10px;
    text-align: center;
  }
  
  .table-credits {
    font-size: 12px;
    margin-top: 10px;
  }
  
  /* FOOTER STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .page-footer {
    position: fixed;
    right: 0;
    bottom: 50px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 1;
    background: var(--white);
  }
  
  .page-footer a {
    display: flex;
    margin-left: 4px;
  }
  