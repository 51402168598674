.name{
    font-family: Bai Jamjuree;
font-size: 22px;
font-weight: 600;
line-height: 30px;
text-align: left;
color:#EAEAEB;
}

.email{
    font-family: Public Sans;
font-size: 14px;
font-weight: 400;
line-height: 16.45px;
text-align: left;

}

.team{
    font-family: Bai Jamjuree;
    font-size: 11px;
    font-weight: 500;
    line-height: 13.75px;
    text-align: center;
    width: Hug (69px)px;
background-Color:#2B343B;
height: Hug (22px)px;
gap: 8px;
border-radius: 29px;
opacity: 0px;
padding:8px;
}

.heading{
    font-family: Bai Jamjuree;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: #161C24;
    }

    .line{
        border:1px solid #BCBEC1;
    }

.textHeading{
    font-family: Bai Jamjuree;
font-size: 14px;
font-weight: 600;
line-height: 20px;
text-align: left;
color:#161C24;
}

.icon{
    font-family: Manrope;
font-size: 10px;
font-weight: 500;
line-height: 20px;
text-align: left;

}

.desc{
    font-family: Public Sans;
font-size: 12px;
font-weight: 400;
line-height: 16px;
text-align: left;

}

.box{
    width: 262px;
height: 189px;
top: 406px;
left: 31px;
gap: 0px;
border-radius: 8px 0px 0px 0px;
border: 1px;
opacity: 0px;
border: 1px solid #EAEAEB
}

.bar{
    width: 100%;
height: 189px;
top: 603px;
left: 31px;
gap: 8px;
border-radius: 8px 0px 0px 0px;
border: 1px 0px 0px 0px;
opacity: 0px;

}

.question{
    font-family: Manrope;
font-size: 14px;
font-weight: 600;
line-height: 16.39px;
text-align: left;
color: #16181F;

}

.answer{
    font-family: Public Sans;
font-size: 16px;
font-weight: 500;
line-height: 18.1px;
text-align: left;

}